<template>
    <div>
        <!--탑메뉴-->
        <topbar-comp></topbar-comp>
        <left-bar-comp>
            <sports-left-bar-comp :display-position="sportsConst.GAME_DISPLAYPOSITION_1x2"></sports-left-bar-comp>
        </left-bar-comp>

        <div class="content_section" style="position: relative">
            <popup-comp></popup-comp>
            <div class="main">
                <div class="main_img">
                    <swiper :options="swiperOption">
                        <swiper-slide>
                            <img src="../../assets/images/main/ptn/main01.jpg" alt="" style="width: 100%">
                        </swiper-slide>
<!--                        <swiper-slide>-->
<!--                            <img src="../../assets/images/main/drone/main01.jpg" alt="" style="width: 100%">-->
<!--                        </swiper-slide>-->
<!--                        <div class="swiper-pagination" slot="pagination"></div>-->
                    </swiper>
                </div>

                <div class="sub_img">
                    <div class="sub">
                        <router-link to="/sports" tag="span" style="cursor: pointer">
                            <img src="../../assets/images/main/ptn/bn01.jpg" alt="">
                        </router-link>
                    </div>
                    <div class="sub">
                        <router-link to="/sports_inplay" tag="span">
                            <img src="../../assets/images/main/ptn/bn02.jpg" alt="">
                        </router-link>
                    </div>
                    <div class="sub">
                        <router-link to="/casinohonor" tag="span">
                            <img src="../../assets/images/main/ptn/bn03.jpg" alt="">
                        </router-link>
                    </div>

                    <div class="sub">
                        <router-link to="/leisure/eospowerball1m" tag="span">
                            <img src="../../assets/images/main/ptn/bn04.jpg" alt="">
                        </router-link>
                    </div>
                    <div class="sub pc">
                        <router-link to="/event" tag="span">
                            <img src="../../assets/images/main/ptn/bn05.jpg" alt="">
                        </router-link>
                    </div>
                </div>
                <div class="sub_mobile">
                    <mobile-menu-items-comp></mobile-menu-items-comp>
                    <right-bar-banner-comp></right-bar-banner-comp>

                </div>
            </div>
        </div>
        <!-- right 메뉴 -->
        <right-bar-comp>
            <!--내정보-->
            <user-info-comp slot="userinfo"></user-info-comp>
            <right-buttons-comp slot="btns"></right-buttons-comp>
            <!--배팅카트-->
            <sports-bet-cart-comp slot="betcart" :display-position="displayPosition"></sports-bet-cart-comp>
            <right-bar-banner-comp slot="banner"></right-bar-banner-comp>
        </right-bar-comp>

        <foot-comp></foot-comp>

    </div>


</template>

<script>
    import RightBarComp from "../../components/RightBarComp";
    import TopbarComp from "../../components/TopbarComp";
    import {swiper, swiperSlide} from "vue-awesome-swiper"
    import "swiper/dist/css/swiper.css";
    import FootComp from "../../components/FootComp";
    import SportsLeftBarComp from "../../components/sports/SportsLeftBarComp";
    import LeftBarComp from "../../components/LeftBarComp";
    import sportsConst from "../../common/sportsConst";
    import inplayConst from "../../common/inplayConst";
    import RightBarBannerComp from "../../components/RightBarBannerComp";
    import SportsBetCartComp from "../../components/sports/SportsBetCartComp";
    import {getSportsInplayGames} from "../../network/sportsInplayRequest";
    import {WEBSOCKET_URL} from "../../common/const";
    import {getNoticeList} from "../../network/userRequest";
    import {postionMixin} from "../../common/mixin";
    import MobileMenuItemsComp from "../../components/MobileMenuItemsComp";
    import UserInfoComp from "../../components/UserInfoComp";
    import RightButtonsComp from "../../components/RightButtonsComp";
    import SportsMainShowComp from "../../components/sports/SportsMainShowComp";
    import {getRecentlyGames} from "../../network/sportsRequest";
    import PopupComp from "../../components/PopupComp";

    export default {
        name: "Main",
        mixins: [postionMixin],
        components: {
            PopupComp,
            SportsMainShowComp,
            RightButtonsComp,
            UserInfoComp,
            MobileMenuItemsComp,
            SportsBetCartComp,
            RightBarBannerComp,
            LeftBarComp, SportsLeftBarComp, FootComp, TopbarComp, RightBarComp, swiper, swiperSlide
        },
        data() {
            return {
                noticeList: [],
                noticePageSize: 8,
                inplayList: [],
                wsData: null,
                websocket: null,
                path: WEBSOCKET_URL,
                position: "메인",
                sportsConst,
                inplayConst,
                displayPosition: this.$store.state.displayPostion,
                soccer: [],
                base: [],
                basket: [],
                hokey: [],
                esports: [],
                vol: [],
                swiperOption: {
                    pagination: {el: '.swiper-pagination'},
                    autoplay: {},
                    disableOnInteraction: false,
                    delay: 2000,
                },
            }
        },
        methods: {},
        created() {
            getRecentlyGames().then(res => {
                if (res.data.success) {
                    let recentlryGames = res.data.data
                    this.soccer = recentlryGames.soccer
                    this.basket = recentlryGames.bascket
                    this.base = recentlryGames.base
                    this.vol = recentlryGames.vol
                    this.hokey = recentlryGames.hockey
                    this.esports = recentlryGames.esports
                }
            })
        }
    }
</script>
<style scoped>
    @import url("../../assets/css/base.css");
    @import url("../../assets/css/common.css");
    @import url("../../assets/css/style.css");
    @import url("../../assets/css/media.css");
    @import url("../../assets/css/sports.css");

    .main .sub_img {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        margin-top: 16px;
    }

    .main .sub_img .sub {
        width: 19.8%;
        cursor: pointer;
    }

    .main .sub_img img {
        width: 100%;
    }

    .main .sub_mobile {
        display: none;
        width: 100%;
    }

    .main .mobile {
        display: none;
    }

    @media screen and (max-width: 1024px) {
        .main .sub_img .sub {
            display: none !important;
            width: 49.9% !important;
            padding: 8px 8px;
        }

        .main .sub_mobile {
            display: block !important;
        }

        .main .pc {
            display: none !important;
        }

        .main .mobile {
            display: block !important;
        }
    }


</style>
